import React from 'react';

import Link, { LinkVariants } from '../switchback/Link/Link';
import css from './ParkList.module.css';

const ParkList = props => {
  const { section } = props;
  return (
    <React.Fragment>
      <div className={css.parkList}>
        {section.more_parks.map(region => {
          return (
            <div className={css.region} key={region.name}>
              <div className={css.regionTitle}>
                <h3>{region.name}</h3> <div className={css.noBreak} />
                {region.list.slice(0, 1).map(park => {
                  return (
                    <Link
                      variant={LinkVariants.underlineHover}
                      href={park.url}
                      className={css.link}
                      key={park.title}>
                      {park.title}
                    </Link>
                  );
                })}
              </div>
              {region.list.slice(1, region.list.length - 2).map(park => {
                return (
                  <Link
                    variant={LinkVariants.underlineHover}
                    href={park.url}
                    className={css.link}
                    key={park.title}>
                    {park.title}
                  </Link>
                );
              })}
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default ParkList;
