import isEmpty from 'lodash/isEmpty';

import apiRequest from '@/services/apiRequest';
import { getSearchApi } from '@/utility/getCoreApi';
import { logger } from '@/utility/logger';

export const getStaticPageData = async ({ locale, url_path }) => {
  const path = `/${url_path}`;
  const url = seekerUrl({ locale, url_path: path });
  const [seekerData] = (await apiRequest({ url })) || [];
  return seekerData;
};

const assembleServerObjects = () => {
  if (typeof window !== 'undefined') {
    const req = {
      path: window.location.pathname,
    };
    const res = {
      status: statusCode => {
        window.status = statusCode;
      },
      end: () => {},
    };

    return { req, res };
  }
};

const getPageData = async ({ req: rawReq, res: rawRes, query }) => {
  let req = rawReq;
  let res = rawRes;
  if (!req && !res) {
    const clientObjects = assembleServerObjects();
    req = clientObjects.req;
    res = clientObjects.res;
  }

  let path = req.path;

  if (!path) {
    // /path/exmaple?query=parameter_not_necesary
    // /blog
    // /locality?locale=en-us&url_path=/rv-rental/texas/austin
    // /event-guide-directory?locale=en-us&url_path=/guide/events
    const requestUrl = req.url;

    // /path/exmaple
    // /blog
    // /locality
    // /event-guide-directory
    const [urlWithoutQuery] = requestUrl.split('?');
    const [urlWithoutHash] = urlWithoutQuery.split('#');

    path = urlWithoutHash; // The Next.js router does not provide a path variable in its request object (IncommingMessage)
  }

  if (req?.wagtailPreview) return req?.body; // Return preview data immediately if it exists

  const { locale, url_path } = query;

  if (!locale || !url_path) return send(res, 400); // 400 Bad Request if it doesn't have locale and url_path

  const url = seekerUrl({ locale, url_path }); // Construct Seeker request url

  const [seekerData] = (await apiRequest({ url })) || []; // Fetch data from Seeker
  if (isEmpty(seekerData) || !seekerData?.metadata) return handleEmptyData({ req, res }); // 404 on empty data

  return seekerData; // Return Seeker data to page if no errors
};

const seekerUrl = ({ locale, url_path }) =>
  `${getSearchApi()}/content?filter=metadata.locale=${locale}&filter=metadata.url_path=${url_path}`;

const send = (res, statusCode) => {
  if (res.statusCode) {
    res.statusCode = statusCode;
  } else {
    res.status(statusCode);
  }
  res.end();
};

const handleEmptyData = ({ res, req }) => {
  logger.captureExceptionWithDatadog(new Error('Empty response from Seeker!'), {
    url: req.path,
  });
  send(res, 404);
};

export default getPageData;
