import React, { useEffect } from 'react';

import Breadcrumbs from '@/components/common/Breadcrumbs';
import MaxWidth from '@/components/common/layout/MaxWidth';
import ParkList from '@/components/park-guide-directory/ParkList';
import { CMSPageData, CMSPageMetadata, CMSPageType, ParkType } from '@/services/getCMSPageData';
import { trackEvent } from '@/services/track-event';

import css from './ParkGuideDirectoryTemplate.module.css';

interface ParkGuideSubDirectoryPageMetadata extends CMSPageMetadata {
  page_type: CMSPageType.ParkGuideSubDirectory;
  sections: {
    park_type: ParkType;
  }[];
}

export interface ParkGuideSubDirectoryPageData extends CMSPageData {
  metadata: ParkGuideSubDirectoryPageMetadata;
}

const nameMaps = {
  national: 'National',
  state: 'State',
  canada: 'Canadian',
  nat_forest: 'National Forest',
  coe: 'COE',
  blm: 'BLM',
};

const ParkGuideDirectoryTemplate: React.FC<
  React.PropsWithChildren<ParkGuideSubDirectoryPageData>
> = props => {
  const { breadcrumbs, sections } = props.metadata;

  useEffect(() => {
    trackEvent({
      event: 'Locality : Viewed page',
      pageType: 'Park guide sub-directory',
    });
  }, []);

  return (
    <div className={css.parkDirectoryContainer}>
      <MaxWidth className="py-4">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </MaxWidth>
      {sections.map(section => {
        return (
          <MaxWidth id={section.park_type} className="mb-8" key={section.park_type}>
            <h2 className="mb-4">
              All <span>{nameMaps[section.park_type]}</span> Parks
            </h2>
            <ParkList section={section} />
          </MaxWidth>
        );
      })}
    </div>
  );
};

export default ParkGuideDirectoryTemplate;
