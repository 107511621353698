import { NextPage } from 'next';
import React from 'react';

import HeadMeta from '@/components/static-pages/HeadMeta';
import ParkSubDirectoryTemplate, {
  ParkGuideSubDirectoryPageData,
} from '@/components/templates/ParkSubDirectoryTemplate';
import getPageData from '@/services/getPageData.js';

const ParkSubDirectory: NextPage<ParkGuideSubDirectoryPageData> = props => {
  const { title, seo_description, canonical_url } = props.metadata;

  return (
    <React.Fragment>
      <HeadMeta
        title={title}
        description={seo_description}
        canonicalUrl={canonical_url}
        noindex={!!props.fallback}
      />
      <ParkSubDirectoryTemplate {...props} />
    </React.Fragment>
  );
};

ParkSubDirectory.getInitialProps = async ({ req, res, query }) => {
  const pageData = await getPageData({ req, res, query });

  return { ...pageData };
};
export default ParkSubDirectory;
