import React from 'react';

import css from './MaxWidth.module.css';

interface IProps {
  className?: string;
  collapseMobile?: boolean;
  id?: string;
}

const MaxWidth: React.FC<React.PropsWithChildren<IProps>> = ({
  className = '',
  children,
  collapseMobile,
  id,
}) => {
  const collapseStyle = collapseMobile ? css.collapseMobile : '';
  return (
    <div id={id} className={`flex flex-col w-full px-4 max-w-xxl ${collapseStyle} ${className}`}>
      {children}
    </div>
  );
};

export default MaxWidth;
