import React from 'react';

import css from './Breadcrumbs.module.css';

const Breadcrumbs = props => {
  const { breadcrumbs, hideHome, textColor } = props;

  return (
    <ol
      itemScope
      itemType="http://schema.org/BreadcrumbList"
      className={`${css.breadcrumbs} ${textColor ? textColor : css.defaultTextColor}`}>
      {breadcrumbs.map((breadcrumb, index) => {
        const title = hideHome || index !== 0 ? breadcrumb.title : 'Home';
        // eslint-disable-next-line no-nested-ternary
        const url = breadcrumb.url?.length ? breadcrumb.url : title === 'Home' ? '/' : false;

        return (
          <React.Fragment key={breadcrumb.title}>
            <li
              className="whitespace-nowrap"
              itemProp="itemListElement"
              itemScope
              itemType="http://schema.org/ListItem">
              {url ? (
                <a
                  href={url}
                  title={title}
                  itemScope
                  itemType="http://schema.org/Thing"
                  itemProp="item"
                  itemID={url}>
                  <span itemProp="name">{title}</span>
                </a>
              ) : (
                <span itemProp="name">{title}</span>
              )}
              <meta itemProp="position" content={index + 1} />
            </li>
          </React.Fragment>
        );
      })}
    </ol>
  );
};
export default Breadcrumbs;
